import { createStyles, makeStyles } from '@material-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createUserPersonalData, getUserData } from '../api/auth';
import { history } from '../api/history';
import Logo from '../assets/img/logo.svg';
import FormikAddressSearchField from '../components/FormikAddressSearchField';
import FormikPhoneField from '../components/FormikPhoneField';
import FormikPrivacyCheckbox from '../components/FormikPrivacyCheckbox';
import FormikSelectField from '../components/FormikSelectField';
import FormikTextField from '../components/FormikTextField';
import { userInfoFetchSuccess, userLogout } from '../store/actions/user';
import { StoreState } from '../store/types';

const useStyles = makeStyles((theme) =>
  createStyles({
    itemMargin: {
      marginTop: '16px',
    },
  })
);

const PersonalDataForm = () => {
  const classes = useStyles();
  const user = useSelector((store: StoreState.All) => store.user);
  const dispatch = useDispatch();
  const initialValues = {
    fio: '',
    address: '',
    phone: '',
    organization_name: '',
    position: '',
    consent: false,
  };

  useEffect(() => {
    if (user.extra?.consent_processing_pd) {
      history.push('/my-courses');
    }
  }, [user]);

  const onSubmit = (values, { setSubmitting, setFieldError }) => {
    createUserPersonalData(values)
      .then(() => {
        getUserData()
          .then((res) => {
            if (!res) {
              return;
            }

            dispatch(userInfoFetchSuccess(res.data));

            if (res.data.is_superuser) {
              history.push('/admin/users');
            } else if (res.data.groups.some(({ name }) => name === 'coach')) {
              history.push('/courses');
            } else {
              history.push('/my-courses');
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          toast.error(error.response.statusText);
        } else if (error.response && error.response.status === 400) {
          for (const [field, err] of Object.entries(error.response.data)) {
            setFieldError(field, err as string);
          }
        } else if (error.response && error.response.status === 401) {
          toast.error(error.response.data.detail);
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="overlay" data-class="login-overlay">
      <div className="login personal-data" data-class="login">
        <div className="login__inner personal-data__inner">
          <Link to="/">
            <img
              className="login__logo"
              src={Logo}
              alt="Система дистанционного обучения Скилл"
            />
          </Link>

          <h1 className="login__title">Укажите ваши данные</h1>
          <p>
            Заполните информацию о себе и подтвердите согласие на обработку
            персональных данных
          </p>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form action="#">
              <FormikTextField name="fio" type="text" label="ФИО (полностью)" />
              <ErrorMessage name="fio" className="error" component="span" />
              <FormikAddressSearchField
                className={classes.itemMargin}
                name="address"
                label="Адрес по прописке (полностью)"
              />
              <ErrorMessage name="address" className="error" component="span" />
              <FormikPhoneField
                className={classes.itemMargin}
                name="phone"
                label="Контактный телефон"
              />
              <ErrorMessage name="phone" className="error" component="span" />
              <FormikSelectField
                className={classes.itemMargin}
                name="organization_name"
                label="Наименование организации"
              />
              <ErrorMessage
                name="organization_name"
                className="error"
                component="span"
              />
              <FormikTextField
                className={classes.itemMargin}
                name="position"
                label="Должность"
              />
              <ErrorMessage
                name="position"
                className="error"
                component="span"
              />
              <button className="form__button" type="submit">
                Войти
              </button>
              <FormikPrivacyCheckbox
                className={classes.itemMargin}
                name="consent"
              />
              <ErrorMessage name="consent" className="error" component="span" />
            </Form>
          </Formik>

          <button
            onClick={() => dispatch(userLogout())}
            style={{ marginTop: '30px', fontStyle: 'italic' }}
          >
            <p style={{ marginBottom: 0 }}>
              Авторизоваться под другой учетной записью
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDataForm;
