import { connect } from 'react-redux';
import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../assets/img/logo.svg';
import { userLogout } from '../store/actions/user';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';

function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getShortName() {
    const last_name = String(props.user.last_name);
    const first_name = String(props.user.first_name);
    return last_name.charAt(0) + first_name.charAt(0);
  }

  function handleLogout() {
    console.log('logout');
    props.userLogout();
  }

  return (
    <header className="header">
      <div className="header__logo">
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div
        className="header__user"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div className="user__link">
          <div className="user__arrow"></div>
          {props.user.id ? (
            <>
              <h3 className="user__name">
                {props.user.last_name} {props.user.first_name}
              </h3>
              <div className="user__icon">{getShortName()}</div>
            </>
          ) : (
            <>
              <h3 className="user__name">
                <Skeleton width={185} />
              </h3>
              <div className="user__icon">
                <Skeleton variant="circle" />
              </div>
            </>
          )}
        </div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem onClick={handleClose}>Профиль</MenuItem>
          <MenuItem onClick={handleClose}>Настройки</MenuItem> */}
        <MenuItem onClick={handleLogout}>Выйти</MenuItem>
      </Menu>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(userLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleMenu);
