import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.svg';
import iconLogin from '../../assets/images/icon-login.svg';
import { Link } from 'react-router-dom';
import useOnMount from '../../hooks/useOnMount';
import { getMainPageHeader, getMainMenu } from '../../api/admin';

const HeaderPublicPage = () => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState({ number: '', address: '' });
  const [menuItems, setMenuItems] = useState<any>([]);

  const links = [
    { link: '/info', name: 'Сведения об образовательной организации' },
    { link: '#!', name: 'Партнеры' },
    { link: '/trainers', name: 'Тренерский состав' },
    { link: '/programs', name: 'Образовательные программы' },
    { link: '#!', name: 'Условия сотрудничества' },
    { link: '/contacts', name: 'Контакты' },
  ];

  useOnMount(() => {
    getMainPageHeader()
      .then((res) => setContent(res.data))
      .catch((err) => console.log(err));
  });

  useEffect(() => {
    getMainMenu()
      .then((res) => {
        if (!res) {
          return;
        }
        setMenuItems(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setMenuItems]);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__inner">
            <div className="header__logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="header__phone">
              <a
                className="header__link"
                href={`tel:+${
                  content.number &&
                  content.number
                    .split('')
                    .filter((x) => !isNaN(Number(x)))
                    .join('')
                }`}
              >
                {content.number}
              </a>
            </div>
            <div className="header__address">
              <a
                className="header__link"
                href="https://www.google.ru/maps/place/%D0%A3%D1%84%D0%B0%D0%BD%D0%B5%D1%82/@54.7404532,55.9889617,17.75z/data=!4m13!1m7!3m6!1s0x43d93a23f167ed69:0x218012ec312dbfce!2z0YPQuy4g0L_RgC3Rgi4g0J7QutGC0Y_QsdGA0Y8sIDQsINCj0YTQsCwg0KDQtdGB0L8uINCR0LDRiNC60L7RgNGC0L7RgdGC0LDQvSwgNDUwMDAx!3b1!8m2!3d54.7397916!4d55.9871321!3m4!1s0x43d93a259216bc47:0xf1db2f9931a3a0de!8m2!3d54.7409561!4d55.9891371"
              >
                {content.address}
              </a>
            </div>

            <Link to="/login" className="header__login">
              <img
                className="header__login__icon"
                src={iconLogin}
                alt="login"
              />
              <p>Войти в личный кабинет</p>
            </Link>
            <div
              className="burger-menu"
              onClick={() => {
                setOpen(true);
              }}
              role="button"
            >
              <div className="burger-menu__icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>Меню</p>
            </div>
          </div>
        </div>
      </header>
      <nav className={`main-nav ${open ? '' : 'close'}`}>
        <button
          className="main-nav__close-btn"
          onClick={() => {
            setOpen(false);
          }}
        ></button>
        <ul className="main-nav__menu">
          {links.map((item, index) => (
            <li key={index}>
              <Link to={item.link}>{item.name}</Link>
            </li>
          ))}
          {menuItems.map((item) => (
            <li key={item.id}>
              <Link to={`/custom-page/${item.id}`}>{item.name}</Link>
            </li>
          ))}
          <li>
            <Link to="/courses">Войти в личный кабинет</Link>
          </li>
          <div className="contact-mobile">
            <div className="header__phone">
              <a
                className="header__link"
                href={`tel:+${
                  content.number &&
                  content.number
                    .split('')
                    .filter((x) => !isNaN(Number(x)))
                    .join('')
                }`}
              >
                {content.number}
              </a>
            </div>
            <div className="header__address">
              <a
                className="header__link"
                href="https://www.google.ru/maps/place/%D0%A3%D1%84%D0%B0%D0%BD%D0%B5%D1%82/@54.7404532,55.9889617,17.75z/data=!4m13!1m7!3m6!1s0x43d93a23f167ed69:0x218012ec312dbfce!2z0YPQuy4g0L_RgC3Rgi4g0J7QutGC0Y_QsdGA0Y8sIDQsINCj0YTQsCwg0KDQtdGB0L8uINCR0LDRiNC60L7RgNGC0L7RgdGC0LDQvSwgNDUwMDAx!3b1!8m2!3d54.7397916!4d55.9871321!3m4!1s0x43d93a259216bc47:0xf1db2f9931a3a0de!8m2!3d54.7409561!4d55.9891371"
              >
                {content.address}
              </a>
            </div>
          </div>
        </ul>
      </nav>
    </>
  );
};

export default HeaderPublicPage;
